import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { defineBlock, EditableText, InnerBlocks } from "eddev/blocks"

export const meta: BlockMeta = {
  title: "Home Rankings",
}

export default defineBlock("home/rankings", (props) => {
  return (
    <BlockWrapper toolbarState="live-index" label="rankings">
      <div className="flex items-start justify-start w-[calc(100%-8px)] mx-auto gap-2 overflow-auto snap-x snap-mandatory scroll-smooth hide-scrollbars md:gap-1 admin-innerblocks:w-full">
        <InnerBlocks
          allowedBlocks={["home/rankings-item"]}
          template={[
            ["home/rankings-item", null],
            ["home/rankings-item", null],
            ["home/rankings-item", null]
          ]}
          orientation="horizontal"
        />
      </div>
    </BlockWrapper>
  )
})